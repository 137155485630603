import flvjs from "flv.js";

export default class MyVideo {
  constructor() {
  }

  static async createPlayer(url, id) {
    const type = MyVideo.getLinkExtension(url);
    // if (!["flv", "mp4"].includes(type)) return;
    if (!MyVideo[id]) {
      MyVideo[id] = {
        id: id,
        myFlvPlayer: undefined
      };
    }
    const videoElement = document.getElementById(id);
    if (videoElement) {
      MyVideo.destroyPlayer(id);
      if ("flv" === type) {
        MyVideo[id].myFlvPlayer = flvjs.createPlayer(
          {
            type: "flv",
            isLive: true,
            url: url
          },
          // {
          //   enableWorker: false, //不启用分离线程
          //   enableStashBuffer: true, //关闭IO隐藏缓冲区
          //   reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
          //   autoCleanupSourceBuffer: true //自动清除缓存
          // }
        );
      }

      // if ("mp4" === type) {
      //   MyVideo[id].myFlvPlayer = flvjs.createPlayer({
      //     type: "mp4",
      //     isLive: false,
      //     url:HiConfig.getHttpRoot()+'video/' + url
      //   });
      // }

      if (MyVideo[id].myFlvPlayer) {
        MyVideo[id].myFlvPlayer.attachMediaElement(videoElement);
        MyVideo[id].myFlvPlayer.load(); //加载
        MyVideo[id].myFlvPlayer.play(); //播放
      }
    }
  }

  static destroyPlayer(id) {
    if (MyVideo[id] && MyVideo[id].myFlvPlayer) {
      MyVideo[id].myFlvPlayer.pause();
      MyVideo[id].myFlvPlayer.unload();
      MyVideo[id].myFlvPlayer.detachMediaElement();
      MyVideo[id].myFlvPlayer.destroy();
      MyVideo[id].myFlvPlayer = null;
    }
  }

  static destroyAll() {
    Object.keys(MyVideo).forEach(item => {
      MyVideo.destroyPlayer(MyVideo[item].id);
    });
  }

  //获取后缀，看是flv或者MP4
  static getLinkExtension(url) {
    // 使用正则表达式匹配链接的文件名和后缀
    const regex = /[^/\\&?]+\.\w{1,5}(?=([?&].*$|$))/;
    const match = url.match(regex);

    if (match) {
      return match[0].split(".").pop();
    }

    // 如果链接中没有后缀，则返回空字符串
    return "";
  }
}
